import React, { useEffect, useState } from "react";
import {
	Menu,
	Dropdown,
	Avatar,
	message,
	Modal,
	Form,
	Row,
	Col,
	Input,
} from "antd";
import { connect } from "react-redux";
import {
	MailOutlined,
	EditOutlined,
	LogoutOutlined,
	UserOutlined,
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/actions/Auth";
import adindexService from "services/AdindexService";
import jwtDecode from "jwt-decode";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { Link } from "react-router-dom";
import { ROW_GUTTER } from "constants/ThemeConstant";

export const NavProfile = ({ signOut }) => {
	const [firstname, setFirstname] = useState("n/a");
	const [lastname, setLastname] = useState("n/a");
	const [email, setEmail] = useState("n/a");
	const [role, setRole] = useState("n/a");
	const [editProfileVisible, setEditProfileVisible] = useState(false);
	const [editProfileForm] = Form.useForm();

	useEffect(() => {
		adindexService
			.getSystemUser(
				JSON.stringify({
					userId: jwtDecode(localStorage.getItem(AUTH_TOKEN)).userId,
				})
			)
			.then((resp) => {
				if (resp.data.error) {
					message.error(resp.data.error.message);
				} else {
					setRole(resp.data.items[0].role);
					setFirstname(resp.data.items[0].firstname);
					setLastname(resp.data.items[0].lastname);
					setEmail(resp.data.items[0].email);
					editProfileForm.setFieldsValue(resp.data.items[0]);
				}
			});

		adindexService
			.getSystemUserRights(
				JSON.stringify({
					userId: jwtDecode(localStorage.getItem(AUTH_TOKEN)).userId,
				})
			)
			.then((resp) => {
				if (resp.data.error) {
					message.error(resp.data.error.message);
				} else {
				}
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleEditProfileClosed = () => {
		setEditProfileVisible(false);
	};
	const handleProfileEdited = (values) => {
		adindexService
			.setSystemUser(
				JSON.stringify({
					id: jwtDecode(localStorage.getItem(AUTH_TOKEN)).userId,
					email: values.email,
					firstname: values.firstname,
					lastname: values.lastname,
				})
			)
			.then((resp) => {
				if (resp.data.error) {
					message.error(resp.data.error.message);
				} else {
					message.success("Update successful");
				}
			});
		setEditProfileVisible(false);
	};

	const profileMenu = (
		<div className="nav-profile nav-dropdown">
			<div className="nav-profile-header">
				<div className="d-flex">
					<Avatar
						size={45}
						icon={<UserOutlined />}
						style={{ backgroundColor: "#f58220" }}
					/>
					<div className="pl-3">
						<h4 className="mb-0">
							{firstname} {lastname}
						</h4>
						<span className="text-muted">{role}</span>
					</div>
				</div>
			</div>
			<div className="nav-profile-body">
				<Menu>
					<Menu.Item key={1}>
						<Modal
							title="Edit your Profile"
							visible={editProfileVisible}
							okText="Update Profile"
							onCancel={handleEditProfileClosed}
							onOk={() => {
								editProfileForm
									.validateFields()
									.then((values) => {
										editProfileForm.resetFields();
										handleProfileEdited(values);
									})
									.catch((info) => {
										console.log("Validate Failed:", info);
									});
							}}
							initialValues={{
								email: { email },
								firstname: { firstname },
								lastname: { lastname },
							}}
						>
							<Form
								form={editProfileForm}
								name="editProfileForm"
								layout="vertical"
							>
								<Row gutter={ROW_GUTTER}>
									<Col xs={24} sm={24} md={24}>
										<Form.Item
											label="Email"
											name="email"
											hasFeedback
											rules={[
												{
													required: true,
													type: "email",
													message: "Please enter a email advertiserId!",
												},
											]}
										>
											<Input
												suffix={<MailOutlined />}
												placeholder="mail@example.com"
											/>
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={ROW_GUTTER}>
									<Col xs={24} sm={24} md={24}>
										<Form.Item
											label="Firstname"
											name="firstname"
											hasFeedback
											rules={[
												{
													required: true,
													message: "Please enter a firstname advertiserId!",
												},
											]}
										>
											<Input suffix={<EditOutlined />} placeholder="Terry" />
										</Form.Item>
									</Col>
								</Row>
								<Row gutter={ROW_GUTTER}>
									<Col xs={24} sm={24} md={24}>
										<Form.Item
											label="Lastname"
											name="lastname"
											hasFeedback
											rules={[
												{
													required: true,
													message: "Please enter a lastname advertiserId!",
												},
											]}
										>
											<Input suffix={<EditOutlined />} placeholder="Pratchet" />
										</Form.Item>
									</Col>
								</Row>
							</Form>
						</Modal>
						<Link to="#" onClick={() => setEditProfileVisible(true)}>
							<Icon className="mr-3" type={EditOutlined} />
							<span className="font-weight-normal">Edit Profile</span>
						</Link>
					</Menu.Item>
					<Menu.Item key={2} onClick={(e) => signOut()}>
						<span>
							<LogoutOutlined className="mr-3" />
							<span className="font-weight-normal">Sign Out</span>
						</span>
					</Menu.Item>
				</Menu>
			</div>
		</div>
	);
	return (
		<Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
			<Menu className="d-flex align-item-center" mode="horizontal">
				<Menu.Item key="profile">
					<Avatar
						icon={<UserOutlined />}
						style={{ backgroundColor: "#f58220" }}
					/>
				</Menu.Item>
			</Menu>
		</Dropdown>
	);
};

export default connect(null, { signOut })(NavProfile);
