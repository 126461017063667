import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route
					path={`${APP_PREFIX_PATH}/home`}
					component={lazy(() => import(`./home`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/reports/commissions`}
					component={lazy(() => import(`./reports-commissions`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/reports/clicks`}
					component={lazy(() => import(`./reports-clicks`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/reports/networks/list`}
					component={lazy(() => import(`./reports-networks-list`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/reports/networks/:networkId`}
					component={lazy(() => import(`./reports-networks`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/credits/generate`}
					component={lazy(() => import(`./accounting-credits-generate`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/credits`}
					component={lazy(() => import(`./accounting-credits-list`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/credits-income`}
					component={lazy(() => import(`./accounting-credits-income`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/accounting/commissions`}
					component={lazy(() => import(`./accounting-commissions`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/accounting/eokoko`}
					component={lazy(() => import(`./accounting-eokoko`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/publisher/list`}
					component={lazy(() => import(`./publisher-list`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/publisher/adspaces`}
					component={lazy(() => import(`./publisher-adspaces`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/publisher/:publisherId`}
					component={lazy(() => import(`./publisher`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/advertiser/list`}
					component={lazy(() => import(`./advertiser-list`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/advertiser/network`}
					component={lazy(() => import(`./advertiser-network`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/advertiser/links`}
					component={lazy(() => import(`./advertiser-links`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/advertiser/showcase`}
					component={lazy(() => import(`./advertiser-showcase`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/redirect/networkpriority`}
					component={lazy(() => import(`./redirect-networkpriority`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/redirect/refererblock`}
					component={lazy(() => import(`./redirect-refererblock`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/system/admin/list`}
					component={lazy(() => import(`./system-admin-list`))}
				/>
				<Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
			</Switch>
		</Suspense>
	);
};

export default React.memo(AppViews);
